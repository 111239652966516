@font-face {
font-family: '__yahooSans_b8938d';
src: url(/_nca/_next/static/media/7553561d3efb1daf-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200 900;
}@font-face {font-family: '__yahooSans_Fallback_b8938d';src: local("Arial");ascent-override: 94.56%;descent-override: 23.94%;line-gap-override: 0.00%;size-adjust: 100.68%
}.__className_b8938d {font-family: '__yahooSans_b8938d', '__yahooSans_Fallback_b8938d'
}.__variable_b8938d {--font-yahoo-sans: '__yahooSans_b8938d', '__yahooSans_Fallback_b8938d'
}

